import {
  ANDROID_PACKAGE_NAME,
  AppEnvironments,
  APP_STORE_ID,
  deeplinkBaseUrl,
  deepLinkPrefix,
  DeepLinkType,
  dynamicLinkBaseUrl,
  getEnvironment,
  IOS_BUNDLE_ID,
  SKIP_APP_PREVIEW_PAGE,
  OneLinkTypes,
  oneLinkBaseUrl,
} from "../../utils";

type ContentItem = {
  id: string;
  title: string;
  imageUrl?: string;
  type?: string;
};

export const contentDeepLink = ({
  selection,
  searchKeyWord,
  articles,
  questions,
  podcasts,
  checklists,
  pregnancyBlogPosts,
  childBlogPosts,
  birthBlogPosts,
  generalBlogPosts,
  offers,
  dietaryAdvices,
  categories,
  contentCollection,
  appTakeOvers,
  miniJourneys,
  competitions,
  locale,
}: {
  selection: string;
  searchKeyWord: string;
  articles: FullArticle[];
  questions: FullQuestionV2[];
  podcasts: FullPodcast[];
  checklists: Checklist[];
  pregnancyBlogPosts: BlogPost[];
  childBlogPosts: ChildBlogPost[];
  birthBlogPosts: BirthStory[];
  generalBlogPosts: GeneralBlogPost[];
  offers: OfferV2[];
  dietaryAdvices: FullDietaryAdvice[];
  categories: ArticleCategory[];
  contentCollection: ContentCollection[];
  appTakeOvers: AppTakeOver[];
  miniJourneys: MiniJourney[];
  competitions: Competition[];
  locale: Locale;
}): ContentItem[] => {
  switch (DeepLinkType[selection as keyof typeof DeepLinkType]) {
    case DeepLinkType.Article: {
      return searchKeyWord === ""
        ? articles.map(article => ({
            id: article.id,
            title: article.translations[locale.key]?.title,
            imageUrl: article.translations[locale.key]?.imageUrl,
          }))
        : articles
            .filter(article =>
              article.translations[locale.key]?.title
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(article => ({
              id: article.id,
              title: article.translations[locale.key]?.title,
              imageUrl: article.translations[locale.key]?.imageUrl,
            }));
    }
    case DeepLinkType.FAQ: {
      return searchKeyWord === ""
        ? questions.map(question => ({
            id: question.id,
            title: question.translations[locale.key].question,
            imageUrl: "",
          }))
        : questions
            .filter(question =>
              question.translations[locale.key].question
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(question => ({
              id: question.id,
              title: question.translations[locale.key].question,
              imageUrl: "",
            }));
    }
    case DeepLinkType.PodcastEpisode: {
      return searchKeyWord === ""
        ? podcasts.map(podcast => ({
            id: podcast.id,
            title: podcast.translations[locale?.key]?.title,
            imageUrl: podcast.imageUrl,
          }))
        : podcasts
            .filter(podcast =>
              podcast.translations[locale?.key]?.title
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(podcast => ({
              id: podcast.id,
              title: podcast.translations[locale?.key]?.title,
              imageUrl: podcast.imageUrl,
            }));
    }
    case DeepLinkType.Checklist: {
      return searchKeyWord === ""
        ? checklists.map(checklist => ({
            id: checklist.id,
            title: checklist.translations[locale?.key]?.name,
            imageUrl: "",
          }))
        : checklists
            .filter(checklist =>
              checklist.translations[locale?.key]?.name
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(checklist => ({
              id: checklist.id,
              title: checklist.translations[locale?.key]?.name,
              imageUrl: "",
            }));
    }
    case DeepLinkType.BlogPost: {
      return searchKeyWord === ""
        ? pregnancyBlogPosts.map(blog => ({
            id: blog.id,
            title: blog.translations[locale?.key]?.title ?? "",
            imageUrl: blog.imageUrl,
          }))
        : pregnancyBlogPosts
            .filter(blog =>
              blog.translations[locale?.key]?.title
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(blog => ({
              id: blog.id,
              title: blog.translations[locale?.key]?.title ?? "",
              imageUrl: blog.imageUrl,
            }));
    }
    case DeepLinkType.ChildBlogPost: {
      return searchKeyWord === ""
        ? childBlogPosts.map(blog => ({
            id: blog.id,
            title: blog.translations[locale.key]?.title,
            imageUrl: blog.imageUrl,
          }))
        : childBlogPosts
            .filter(blog =>
              blog.translations[locale.key]?.title
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(blog => ({
              id: blog.id,
              title: blog.translations[locale.key]?.title,
              imageUrl: blog.imageUrl,
            }));
    }
    case DeepLinkType.GeneralBlogPost: {
      return searchKeyWord === ""
        ? generalBlogPosts.map(blog => ({
            id: blog.id,
            title: blog.translations[locale.key].title,
            imageUrl: blog.imageUrl,
          }))
        : generalBlogPosts
            .filter(blog =>
              blog.translations[locale.key].title
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(blog => ({
              id: blog.id,
              title: blog.translations[locale.key].title,
              imageUrl: blog.imageUrl,
            }));
    }
    case DeepLinkType.BirthStories: {
      return searchKeyWord === ""
        ? birthBlogPosts.map(blog => ({
            id: blog.id,
            title: blog.translations[locale.key].title,
            imageUrl: blog.imageUrl,
          }))
        : birthBlogPosts
            .filter(blog =>
              blog.translations[locale.key].title
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(blog => ({
              id: blog.id,
              title: blog.translations[locale.key].title,
              imageUrl: blog.imageUrl,
            }));
    }
    case DeepLinkType.Offer: {
      return searchKeyWord === ""
        ? offers.map(offer => ({
            id: offer.id,
            title: offer.translations[locale.key].title,
            imageUrl: offer.image,
            type: offer.type,
          }))
        : offers
            .filter(offer =>
              offer?.translations[locale.key]?.title
                ?.toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(offer => ({
              id: offer.id,
              title: offer.translations[locale.key].title,
              imageUrl: offer.image,
              type: offer.type,
            }));
    }
    case DeepLinkType.Podcast: {
      return searchKeyWord === ""
        ? podcasts.map(podcast => ({
            id: podcast.id,
            title: podcast.translations[locale?.key]?.title,
            imageUrl: podcast.imageUrl,
          }))
        : podcasts
            .filter(podcast =>
              podcast.translations[locale?.key]?.title
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(podcast => ({
              id: podcast.id,
              title: podcast.translations[locale?.key]?.title,
              imageUrl: podcast.imageUrl,
            }));
    }
    case DeepLinkType.Dietary: {
      return searchKeyWord === ""
        ? dietaryAdvices.map(dietaryAdvice => ({
            id: dietaryAdvice.id,
            title: dietaryAdvice.translations[locale.key].name,
            imageUrl: "",
          }))
        : dietaryAdvices
            .filter(dietaryAdvice =>
              dietaryAdvice.translations[locale.key].name
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(dietaryAdvice => ({
              id: dietaryAdvice.id,
              title: dietaryAdvice.translations[locale.key].name,
              imageUrl: "",
            }));
    }
    case DeepLinkType.ArticleCategories: {
      return searchKeyWord === ""
        ? categories.map(category => ({
            id: category.id,
            title: category.translations[locale.key]?.title,
            imageUrl: "",
          }))
        : categories
            .filter(category =>
              category.translations[locale.key]?.title
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(category => ({
              id: category.id,
              title: category.translations[locale.key].title,
              imageUrl: "",
            }));
    }
    case DeepLinkType.ContentCollection: {
      return searchKeyWord === ""
        ? contentCollection.map(_cc => ({
            id: _cc.id,
            title: _cc.translations[locale.key].title,
            imageUrl: _cc.imageUrl,
          }))
        : contentCollection
            .filter(_cc =>
              _cc.translations[locale.key].title
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(_cc => ({
              id: _cc.id,
              title: _cc.translations[locale.key].title,
              imageUrl: _cc.imageUrl,
            }));
    }
    case DeepLinkType.AppTakeOver: {
      return searchKeyWord === ""
        ? appTakeOvers.map(_cc => ({
            id: _cc.id,
            title: _cc.translations[locale.key]?.title,
            imageUrl: _cc.translations[locale.key]?.imageUrl,
          }))
        : appTakeOvers
            .filter(_cc =>
              _cc.translations[locale.key]?.title
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(_cc => ({
              id: _cc.id,
              title: _cc.translations[locale.key]?.title,
              imageUrl: _cc.translations[locale.key]?.imageUrl,
            }));
    }
    case DeepLinkType.Competition: {
      return searchKeyWord === ""
        ? competitions.map(_cc => ({
            id: _cc.id,
            title: _cc.translations?.[locale.key]?.title,
            imageUrl: _cc.image,
          }))
        : competitions
            .filter(_cc =>
              _cc.translations?.[locale.key]?.title
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(_cc => ({
              id: _cc.id,
              title: _cc.translations?.[locale.key]?.title,
              imageUrl: _cc.image,
            }));
    }
    case DeepLinkType.MiniJourney: {
      return searchKeyWord === ""
        ? miniJourneys.map(_cc => ({
            id: _cc.id,
            title: _cc.translations?.[locale.key]?.headerHeadline,
            imageUrl: _cc.headerImage,
          }))
        : miniJourneys
            .filter(_cc =>
              _cc.translations?.[locale.key]?.headerHeadline
                .toLowerCase()
                .includes(searchKeyWord.toLowerCase())
            )
            .map(_cc => ({
              id: _cc.id,
              title: _cc.translations?.[locale.key]?.headerHeadline,
              imageUrl: _cc.headerImage,
            }));
    }

    default: {
      //change this later
      return [];
    }
  }
};

export const getDeeplinkSuffix = (deepLink: string) =>
  deepLink.match(/.+\/(?<idSuffix>.+)$/).groups?.idSuffix;

export const getDeepLinkPrefix = () =>
  deepLinkPrefix[getEnvironment() as AppEnvironments] ||
  deepLinkPrefix.PRODUCTION;

export const setSearchKeyword = (
  e: React.ChangeEvent<HTMLInputElement>,
  setSearchKeyWord: (searchKeyword: string) => void
) => {
  e.preventDefault();
  if (e.currentTarget.value.length > 3) {
    setSearchKeyWord(e.currentTarget.value);
  } else if (e.currentTarget.value.length === 0) {
    setSearchKeyWord(e.currentTarget.value);
  }
};

export const createDynamicLink = (deepLink: string) =>
  `${dynamicLinkBaseUrl}/?link=${deepLink}&apn=${ANDROID_PACKAGE_NAME}&ibi=${IOS_BUNDLE_ID}&isi=${APP_STORE_ID}&efr=${SKIP_APP_PREVIEW_PAGE}`;

export const createOneLink = (deepLink: string, type: OneLinkTypes): string => {
  const [path = "", searchParams = ""] = deepLink?.split("?") ?? [];
  const oneLinkUrl = oneLinkBaseUrl[getEnvironment() as AppEnvironments];
  const linkType = oneLinkUrl[type] ?? oneLinkUrl["App-to-app"];
  const additionalParams =
    type === "Email-to-app" ? "&link_id={% cio_link_id %}" : "";
  return `${linkType}?af_force_deeplink=true&deep_link_value=${path}&${searchParams}${additionalParams}`;
};

export const createDeepLink = ({
  type,
  id,
  params,
  countryCode = "se",
}: DeepLinkProps) => {
  switch (type) {
    case DeepLinkType.Article:
    case DeepLinkType.FAQ:
    case DeepLinkType.Offer:
    case DeepLinkType.Podcast:
    case DeepLinkType.Checklist:
    case DeepLinkType.ArticleCategories:
    case DeepLinkType.ContentCollection:
    case DeepLinkType.Dietary: {
      return `${deeplinkBaseUrl}${type}/${id}?cc=${countryCode}`;
    }
    case DeepLinkType.AppTakeOver: {
      return `${deeplinkBaseUrl}screen/${type}/${id}?cc=${countryCode}`;
    }
    case DeepLinkType.BlogPost:
    case DeepLinkType.ChildBlogPost:
    case DeepLinkType.GeneralBlogPost:
    case DeepLinkType.BirthStories: {
      return `${deeplinkBaseUrl}blogPosts/${id}?cc=${countryCode}`;
    }
    case DeepLinkType.PodcastEpisode: {
      return `${deeplinkBaseUrl}podcastEpisodes/${params?.podcastId}/${id}?cc=${countryCode}`;
    }
    case DeepLinkType.MiniJourney: {
      return `${deeplinkBaseUrl}screen/miniJourney/${id}?cc=${countryCode}`;
    }
    case DeepLinkType.Competition: {
      return `${deeplinkBaseUrl}screen/competitions/${id}?cc=${countryCode}`;
    }
    default:
      break;
  }
};
