import convert, { MeasuresByUnit } from "convert";

export enum MeasurementSystem {
  Metric = "metric",
  Imperial = "imperial",
}

const GRAMS_PER_OUNCE = 28.349523125;
const GRAMS_PER_POUND = 453.59237;
const MILLIMETERS_PER_INCH = 25.4;
const MILLIMETERS_PER_FOOT = 304.8;

export function formatLengthInImperial(
  millimeters: number,
  displayUnit?: string
): string {
  if (displayUnit) {
    return `${convert(millimeters, "mm").to(
      displayUnit as "ft" | "in"
    )} ${displayUnit}`;
  }

  const feet = Math.floor(millimeters / MILLIMETERS_PER_FOOT);
  const remainingMillimeters = millimeters % MILLIMETERS_PER_FOOT;
  const inches = Math.round(remainingMillimeters / MILLIMETERS_PER_INCH);

  if (inches === 12 || inches === 0) {
    return `${inches ? feet + 1 : feet} ft`;
  }

  if (feet === 0) {
    return `${inches} in`;
  }

  return `${feet} ft ${inches} in`;
}

export function formatLengthInMetric(
  millimeters: number,
  displayUnit?: string
): string {
  if (displayUnit) {
    return `${convert(millimeters, "mm").to(
      displayUnit as "cm" | "mm"
    )} ${displayUnit}`;
  }

  if (millimeters < 100) {
    return `${millimeters} mm`;
  }

  return `${millimeters / 10} cm`;
}

export function formatWeightInImperial(
  grams: number,
  displayUnit?: string
): string {
  if (displayUnit) {
    return `${convert(grams, "g").to(
      displayUnit as "lb" | "oz"
    )} ${displayUnit}`;
  }

  const pounds = Math.floor(grams / GRAMS_PER_POUND);
  const remainingGrams = grams % GRAMS_PER_POUND;
  const ounces = Math.round(remainingGrams / GRAMS_PER_OUNCE);

  if (ounces === 16 || ounces === 0) {
    return `${ounces ? pounds + 1 : pounds} lb`;
  }

  if (pounds === 0) {
    return `${ounces} oz`;
  }

  return `${pounds} lb ${ounces} oz`;
}

export function formatWeightInMetric(
  grams: number,
  displayUnit?: string
): string {
  if (displayUnit) {
    return `${convert(grams, "g").to(
      displayUnit as MeasuresByUnit<"g">
    )} ${displayUnit}`;
  }

  if (grams < 1000) {
    return `${grams} g`;
  }

  return `${grams / 1000} kg`;
}
