import { useCallback } from "react";

import {
  formatLengthInImperial,
  formatLengthInMetric,
  formatWeightInImperial,
  formatWeightInMetric,
  MeasurementSystem,
} from "../helper/measurements";
import { useCountry } from "../context";

export const useMeasurementsFormatter = () => {
  const { currentCountry } = useCountry();

  const formatWeight = useCallback(
    (grams: number, displayUnit?: string) => {
      const unit =
        displayUnit === "primary"
          ? currentCountry?.measurementSystem?.primaryWeightUnit
          : displayUnit;
      const formatFn =
        currentCountry?.measurementSystem?.name === MeasurementSystem.Imperial
          ? formatWeightInImperial
          : formatWeightInMetric;
      return formatFn(grams, unit);
    },
    [
      currentCountry?.measurementSystem?.name,
      currentCountry?.measurementSystem?.primaryWeightUnit,
    ]
  );

  const formatLength = useCallback(
    (grams: number, displayUnit?: string) => {
      const unit =
        displayUnit === "primary"
          ? currentCountry?.measurementSystem?.primaryLengthUnit
          : displayUnit;
      const formatFn =
        currentCountry?.measurementSystem?.name === MeasurementSystem.Imperial
          ? formatLengthInImperial
          : formatLengthInMetric;
      return formatFn(grams, unit);
    },
    [
      currentCountry?.measurementSystem?.name,
      currentCountry?.measurementSystem?.primaryLengthUnit,
    ]
  );

  return { formatWeight, formatLength };
};
