import { Card } from "antd";
import { useMemo } from "react";
import moment from "moment";

import { formatDate } from "../../../utils";
import * as PregUtils from "../../../helper/pregnancy";
import { useToday } from "../../../hooks/useToday";
import { Sorter } from "../../../components/theme";

import BJTable, {
  type BJColumnType,
} from "../../../components/theme/molecules/BJTable";

type PregnancyProps = {
  pregnancies: Array<Pregnancy>;
};

type PregnancytableProps = {
  daysCompleted: string;
  daysLeftEDD: string | number;
  beenPregnant: string;
  week: number;
  month: number;
  trimester: number;
  dateOfDelivery: string;
};

const columns: BJColumnType<PregnancytableProps>[] = [
  {
    title: "Date of delivery",
    dataIndex: "dateOfDelivery",
    key: "dateOfDelivery",
    width: 2,
    ellipsis: true,
  },
  {
    title: "Days completed",
    dataIndex: "daysCompleted",
    key: "daysCompleted",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Days left until EDD",
    dataIndex: "daysLeftEDD",
    key: "daysLeftEDD",
    width: 2,
    ellipsis: true,
  },
  {
    title: "Been pregnant",
    dataIndex: "beenPregnant",
    key: "beenPregnant",
    width: 2,
    ellipsis: true,
  },
  {
    title: "Week",
    dataIndex: "week",
    key: "week",
    width: 1,
    ellipsis: true,
  },
  {
    title: "Month",
    dataIndex: "month",
    key: "month",
    width: 1,
    ellipsis: true,
  },
  {
    title: "Trimester",
    dataIndex: "trimester",
    key: "trimester",
    width: 1,
    ellipsis: true,
  },
];

export const PregnancyInformation = ({ pregnancies }: PregnancyProps) => {
  const today = useToday();

  const computedPregnancies = useMemo(() => {
    const currentDate = moment(today).utc();
    const details = pregnancies.map(pregnancy => {
      if (!pregnancy.dueDateString && !pregnancy.dueDate) {
        return {
          daysCompleted: "-",
          daysLeftEDD: "-",
          beenPregnant: "-",
          week: 0,
          month: 0,
          trimester: 0,
          dateOfDelivery: "-",
        };
      }

      const {
        daysPassed,
        pregnancyLength,
        daysLeft,
        weeksPassed,
        weekDaysPassed,
        pregnancyWeek,
        pregnancyMonth,
        trimester,
        dueDateString,
      } = PregUtils.calculatePregnancy({
        pregnancy: {
          ...pregnancy,
          dueDateString:
            pregnancy.dueDateString ||
            formatDate(pregnancy.dueDate, "YYYY-MM-DD"),
        },
        currentDate,
      });

      return {
        daysCompleted: `${daysPassed} of ${pregnancyLength.days}`,
        daysLeftEDD: daysLeft < 0 ? `+${Math.abs(daysLeft)}` : daysLeft,
        beenPregnant: `${weeksPassed}w + ${weekDaysPassed}d`,
        week: pregnancyWeek,
        month: pregnancyMonth,
        trimester,
        dateOfDelivery: moment(dueDateString).format("D MMM YYYY"),
      };
    });

    return details;
  }, [pregnancies, today]);

  return (
    <Card title="Pregnancy Information" bordered={false}>
      <BJTable
        hideRecordCount
        pagination={false}
        columns={columns}
        dataSource={computedPregnancies}
      />
    </Card>
  );
};
