import { remoteConfig } from "../firebase";
import { logException } from "../utils/exceptionLogger";

type RemoteConfigKeys = "CIO_NOTIFICATION_TOPICS";

export const getRemoteConfig = (key?: RemoteConfigKeys) => {
  try {
    const response = remoteConfig.getAll();

    if (key) {
      return JSON.parse(response[key].asString());
    }

    return response;
  } catch (error) {
    logException(error);
    console.error("Error during parsing of data from remote config: ", error);

    return [];
  }
};
