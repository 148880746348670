import { Fragment } from "react";
import { Card } from "antd";

import { Sorter } from "../../../components/theme/util/sorter";

import BJTable, {
  BJColumnType,
} from "../../../components/theme/molecules/BJTable";

type AdditionalInfo = {
  value: string;
  date: Date | null | undefined;
  text?: string | null | undefined;
};

type ChildImage = {
  monthNumber: number;
  imageUrl: string;
};

enum ChildEvents {
  OUTSIDE_TUMMY = "outsideTummy",
  POSTPARTUM = "postpartum",
}

export type DisplayChildren = {
  id: string;
  pregnancyId: string | null;
  name: string;
  dateOfBirth: Date;
  dateOfBirthString: string;
  months: number;
  days: number;
  totalWeeks: number;
  totalDays: number;
  daysUntilNextLeap: number;
  gender: number;
  imageUrl: string | null;
  childImages: ChildImage[];
  additionalInfo: AdditionalInfo[];
  lastEventTriggered?: ChildEvents | null;
};

type ChildProps = {
  children?: DisplayChildren[];
};

const columns: BJColumnType<DisplayChildren>[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Date of birth",
    dataIndex: "dateOfBirth",
    key: "dateOfBirth",
    width: 1,
    ellipsis: true,
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
    width: 1,
    ellipsis: true,
  },
  {
    title: "Zodiac Sign",
    dataIndex: "zodiacSign",
    key: "zodiacSign",
    width: 2,
    ellipsis: true,
  },
  {
    title: "Born in gestational week",
    dataIndex: "gestationalWeeK",
    key: "gestationalWeeK",
    width: 1,
    ellipsis: true,
  },
  {
    title: "Number of hours",
    dataIndex: "numberOfHours",
    key: "numberOfHours",
    width: 1,
    ellipsis: true,
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    width: 1,
    ellipsis: true,
  },
  {
    title: "Weight",
    dataIndex: "weight",
    key: "weight",
    width: 1,
    ellipsis: true,
  },
  {
    title: "Length",
    dataIndex: "length",
    key: "length",
    width: 1,
    ellipsis: true,
  },
];

export const ChildInformation = ({ children }: ChildProps) => {
  return (
    <Card title="Children Information" bordered={false}>
      {children ? (
        <BJTable
          hideRecordCount
          pagination={false}
          columns={columns}
          dataSource={children}
        />
      ) : (
        <Fragment />
      )}
    </Card>
  );
};
